.logoImageSize{
    width:25% !important;
}
.logo{
    width: 30% !important;
}

.logoImage{
    width:10% !important;
    display: none;
}
.nav-item .nav-link  {
    color:black !important;
}
@media screen and (max-width:768px) {
    .logoImage{
        display: block;
    }
    .header{
        display: none !important;
    }
}