.carousel-image-width{
    width:100%;
    height:50% !important;
}
.carousel-item{
    width:100% !important;
    height:100% !important;
}
.imageborder{
    width:10rem;
    height:13rem;
    text-align: center;
    margin: auto;
}
.imageborder img{
    border-radius: 50%;
    width:100%;
    height:100%;
}
.card_section2{

    width: auto;
    text-align: center;
    margin-bottom: 20px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    max-height: 10rem;
    min-height: 10rem; 
  }
  .card-body{
      padding:0rem 1rem !important;
  }
  hr{
      margin: auto !important;
  }
  @media only screen and (max-height: 600px) {

    .card_section2 {
        width: auto;
        text-align: center;
        margin-bottom: 20px;
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
        max-height: 14rem;
        min-height: 14rem;
    }
    }

    @media only screen and (max-width: 764px) {
    
        .card_section2{
      
        width: auto;
        text-align: center;
        margin-bottom: 20px;
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
        max-height: 10rem;
        min-height: 10rem;
        min-width: 14rem;
        max-width: 21rem;
      
      
      }
  
      }
      
     
.card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    background-color: #22BFA0 !important;
    opacity: 0.6;
    transition: 0.3s;
    color: white;
  }
  .card-title i:hover{
    transform: scale(1.25) rotate(100deg); 
    color:#18d4ca;
    
  }
  .card:hover{
    transform: scale(1.05);
    box-shadow: 10px 10px 15px rgba(0,0,0,0.3);
  }
  .card-text{
    height:80px;  
  }  
  .card::before, .card::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: scale3d(0, 0, 2);
    transition: transform .3s ease-out 0s;
    background: rgba(255, 255, 255, 0.1);
    content: '';
    pointer-events: none;
  
  }
  .card::before {
    transform-origin: left top;
  }
  .card::after {
    transform-origin: right bottom;
  }
  .card:hover::before, .card:hover::after, .card:focus::before, .card:focus::after {
    transform: scale3d(1, 1, 1);
  }
    .clientsimage .animate_animated{
        width: 12rem;
      height: 4rem;
      
      margin: 10px;
      opacity: 1;
  }
  
  
.clientsimage{
  width: 12rem;
height: 4rem;

margin: 10px;
opacity: 1;
}

.clientsimage .animate_animated{
  width: 12rem;
height: 4rem;

margin: 10px;
opacity: 1;
}
